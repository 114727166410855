export default function () {
  // tailwind breakpoints
  const breakpoint = {
    xxs: '20',
    xs: '40',
    sm: '40',
    md: '48',
    lg: '64',
    xl: '80',
    '2xl': '90'
  }

  // Tailwind font sizes
  const fontSize = {
    xs: '.75',
    sm: '.875',
    base: '1',
    lg: '1.125',
    xl: '1.25',
    '2xl': '1.5',
    '3xl': '1.875',
    '4xl': '2.25',
    '5xl': '3',
    '6xl': '4'
  }

  const fontClamp = (
    minFontSize: keyof typeof fontSize,
    maxFontSize: keyof typeof fontSize,
    minViewPort: keyof typeof breakpoint,
    maxViewPort: keyof typeof breakpoint
  ) => {
    const viewPort = {
      min: parseFloat(breakpoint[minViewPort] || breakpoint.sm),
      max: parseFloat(breakpoint[maxViewPort] || breakpoint['2xl'])
    }

    const font = {
      min: parseFloat(fontSize[minFontSize]),
      max: parseFloat(fontSize[maxFontSize])
    }

    if (font.min >= font.max) {
      throw new Error('Invalid input')
    }

    if (viewPort.min >= viewPort.max) {
      throw new Error('Invalid input')
    }

    const slope = (font.max - font.min) / (viewPort.max - viewPort.min)
    const yAxisIntersection = viewPort.min * -1 * slope + font.min

    return `font-size: clamp(${font.min}rem , ${yAxisIntersection}rem + ${
      slope * 100
    }vw, ${font.max}rem)`
  }

  const a11yTextColor = (color: string, modifier: string) => {
    // if has lightness value (e.g blue-300) split base color and lightness
    const isBase = Number.isNaN(parseInt(color.slice(color.length - 3), 10))
    const lightness = isBase ? 300 : parseInt(color.slice(color.length - 3), 10)
    const baseColor = isBase ? color : color.slice(0, color.length - 4)

    if (modifier) {
      return `
    ${modifier}bg-${baseColor}-${lightness}
    ${modifier}text-${lightness > 499 ? 'white' : 'black'}
    `
    } else {
      return `
    bg-${baseColor}-${lightness}
    text-${lightness > 499 ? 'white' : 'black'}
    `
    }
  }

  return { a11yTextColor, fontClamp }
}
